.dialog-location {
  .title-location {
    display: flex;
    justify-content: space-between;
    svg {
      color: #1976d2;
    }
    .snackbar-url-copy {
      font-size: 0.8rem;
      position: absolute;
      z-index: 3;
      background: #d8def3;
      padding: 0.1rem 0.1rem;
      border-radius: 0.3rem;
      width: 5rem;
      margin-bottom: 3rem;
    }
  }
  .dialogue-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 10px !important;
    & .checkbox-wrap {
      font-size: 15pt;
      & input {
        width: 1.6em;
        height: 1.6em;
        border-radius: 0.15em;
        margin-right: 0.5em;
        border: 0.15em;
      }
    }
  }
  .update-location-button {
    min-width: 200px;
    width: 15rem;
    padding: 5px 0;
    font-size: 10pt;
  }
}
.button-wrapper {
  margin-top: -50px;
}
