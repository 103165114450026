.ADX-main-container {
  width: calc(100% - 12px);
  float: left;
  padding-top: 10px;
  background-color: rgb(246 247 248);
}

.setting-details-title-project {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.setting-details-title-projectId {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.title {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: 'Inria Sans';
}
.contentTextElect {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.save-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding-bottom: 58px;
}
.button-spacing {
  padding-left: 5px;
}
.adx-details-title {
  font-size: 21pt;
  color: #383838;
  font-weight: bold;
  width: 70%;
}
