.page-container {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  .main-grid-container {
    .step-description {
      color: #5f9cd9;
      font-style: italic;
    }
    .step-description-teams-settings {
      font-size: 0.9rem;
    }
    .step-button {
      margin: 1rem;
      height: fit-content;
      width: 15rem;
    }
    .stepper-icon-container {
      border-radius: 50%;
      overflow: hidden;
      width: 50px;
      height: 50px;
      .stepper-icon {
        background-color: #ccc;
        z-index: 1;
        color: #fff;
        width: 50px;
        height: 50px;
        background-image: linear-gradient(
          136deg,
          rgb(70 115 180) 0%,
          rgb(106 163 221) 50%,
          rgb(74 116 179) 100%
        );
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        padding: 8px;
      }
    }
    .project-container-button {
      height: 50px !important;
      padding-left: 20px;
    }
    .project-container {
      height: 300px;
      margin-top: 20px;
      margin-bottom: 20px;
      .project-image-container {
        height: 100%;

        .project-image {
          padding: 0 10px 0 10px;
          .project-image-container {
            width: calc(30% - 40px);
            float: left;
            padding: 20px 20px 0px 0px;
          }

          .project-image-title {
            height: 35px;
            color: rgba(0, 0, 0, 0.6);
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            padding: 0;

            text-overflow: ellipsis;
            max-width: 100%;
          }
          .project-image-icon {
            left: 0px;
            top: 0px;
            width: 100% !important;
            height: 100% !important;
            color: gray;
          }

          .project-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .project-image-input {
            width: 100%;
            height: 30px;
            margin-top: 10px;
          }
          .project-image-wrap {
            float: left;
            width: -webkit-fill-available;
            height: 200px;
            cursor: pointer;
            overflow: hidden;
          }
        }
      }
      .member-addition-error-container {
        color: red;
        padding: 10px;
      }
      .project-details-container {
        height: 100%;
        padding-top: 30px;
        .teams-detail-input-container {
          height: 100px !important;
        }
        .project-detail-input-container {
          height: 55px;
          .project-detail-input {
            width: 100%;
            height: 100%;
          }
          .MuiInputBase-root,
          .MuiAutocomplete-root,
          .MuiFormControl-root {
            height: 100% !important;
            margin: 0px;
            margin-right: 10px;
          }
        }

        .pdescription {
          height: calc(50% - 20px);
        }
        .step-button {
          width: 190px;
        }
      }
    }
    .code-snippet-container {
      max-width: 1000px;
      font-family: monospace;
      background-color: #f0f0f0;
      padding: 0 20px 20px 20px;
      border-radius: 8px;
      .code-snippet-code {
        white-space: pre-wrap;
      }
      .copy-button-container {
        padding-top: 50px;
        .copy-button {
          padding: 8px;
          cursor: pointer;
          background-color: #4caf50;
          color: white;
          border: none;
          border-radius: 4px;
        }
      }
    }
  }
}
.teams-settings-container {
  height: 450px !important;
}
.alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.alert-body {
  width: 525px;
  height: 425px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .center-alert {
    text-align: center;
  }
  .webhook-alert-body {
    width: 625px;
    height: 425px;
  }
}
.teams-input-form {
  width: 50rem;
  display: flex;
}
