.inputs-container {
  display: flex;
  gap: 10px;
  .image-container {
    flex-basis: 30%;
    margin: 1rem 0;
    .error-class {
      text-align: center;
    }
  }
  .textfield-container {
    padding-right: 2rem;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto new rows */
    justify-content: space-between;
    .MuiTextField-root {
      padding-right: 2rem;
    }
  }
}

.checkbox-container {
  padding: 2rem;
  &.MuiFormGroup-root {
    flex-direction: row;
  }
}

.text-input {
  width: calc(50% - 11px);
  float: left;
}
.field-select {
  width: calc(50% - 11px);
  padding-top: 0.4rem;
  .MuiFormControl-root {
    width: 100%;
  }
}
